import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteMethodOfMurderService} from 'services/methodOfMurder'

export const deleteMethodOfMurderAction = createAsyncThunk(
  'methodOfMurder/deleteMethodOfMurder',
  async (id:string , thunkAPI) => {
    try {
      return await deleteMethodOfMurderService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import {getMotiveService} from 'services/motive'

export const getMotiveAction = createAsyncThunk(
  'motive/getMotives',
  async (_ , thunkAPI) => {
    try {
      return await getMotiveService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
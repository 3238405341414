import { createAsyncThunk } from '@reduxjs/toolkit';
import { ageGroupFormType } from 'types/ageGroup';
import {postAgeGroupService} from 'services/ageGroup'

export const postAgeGroupAction = createAsyncThunk(
  'ageGroup/postAgeGroup',
  async (data:ageGroupFormType, thunkAPI) => {
    try {
      return await postAgeGroupService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { timeOfMurderType } from 'types/timeOfMurder';
import { useTranslation } from 'react-i18next';
import { MRT_Localization_AR } from 'material-react-table/locales/ar';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Cell } from 'components/common/cell';
import { formatDate } from 'utils/date';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomModal from 'components/common/modal';
import DeleteConfirmation from 'components/common/deleteConfirmation';
import TimeOfMurderForm from './timeOfMurderForm';

type RegionTableProps = {
  data: timeOfMurderType[],
  onDelete: (id: string) => void,
  onUpdate: (data: timeOfMurderType) => void,
};

const TimeOfMurderTable = ({ data, onDelete, onUpdate }: RegionTableProps) => {
  const { t, i18n } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [target, setTarget] = useState<timeOfMurderType | null>(null);

  const handleOpenDeleteModal = (target: timeOfMurderType) => {
    setTarget(target);
    setOpenDelete(true);
  }

  const handleCloseDeleteModal = () => {
    setOpenDelete(false);
    setTarget(null);
  }

  const handleDeleteDone = () => {
    handleCloseDeleteModal()
  }

  const handleOpenEditModal = (target: timeOfMurderType) => {
    setTarget(target);
    setOpenEdit(true);
  }
  const handleCloseEditModal = () => {
    setOpenEdit(false);
    setTarget(null);
  }
  const handleEditDone = () => {
    handleCloseEditModal()
  }

  const columns = [
    {
      header: t('commonTerms.ID'),
      accessorKey: 'id',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
    },
    {
      header: t('Name(Hebrew)'),
      accessorKey: 'name_hebrew',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
    },
    {
      header: t('Name(Arabic)'),
      accessorKey: 'name_arabic',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
    },
    {
      header: t('commonTerms.Created_By'),
      accessorKey: 'created_by.username',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? cell.getValue() : ''} />
    },
    {
      header: t('commonTerms.Updated_By'),
      accessorKey: 'updated_by.username',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? cell.getValue() : ''} />
    },
    {
      header: t('commonTerms.Created_At'),
      accessorKey: 'created_at',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? formatDate(cell.getValue()) : ''} />
    },
    {
      header: t('commonTerms.Updated_At'),
      accessorKey: 'updated_at',
      Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? formatDate(cell.getValue()) : ''} />,
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data,
    columnResizeDirection: 'rtl',
    enableColumnFilterModes: true,
    enableRowActions: true,
    renderRowActions: ({ row, table }: any) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        <IconButton
          color="error"
          onClick={() => { handleOpenDeleteModal(row.original) }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => { handleOpenEditModal(row.original) }}>
          <EditIcon />
        </IconButton>
      </Box>
    ),
    enableSelectAll: false,
    enableFullScreenToggle: false,
    initialState: { showColumnFilters: false, showGlobalFilter: true, columnVisibility: {'id':false, 'created_by.username': false, 'updated_by.username': false, 'created_at': false, 'updated_at':false  } },
    localization: i18n.language == 'ar' ? MRT_Localization_AR : MRT_Localization_HE
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <CustomModal open={openDelete} onClose={handleCloseDeleteModal}>
        <DeleteConfirmation
          target={target!}
          onClose={handleCloseDeleteModal}
          onDelete={onDelete}
          message={t('deleteTimeOfMurderConfirmation')} />
      </CustomModal>

      <CustomModal open={openEdit} onClose={handleCloseEditModal}>
        <TimeOfMurderForm
          data={target!}
          onClose={handleCloseEditModal}
          title={t('updateTimeOfMurderInfo')}
          onSubmit={onUpdate}
          type='edit' />
      </CustomModal>
    </>
  );
};

export default TimeOfMurderTable;


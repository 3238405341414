import { getUser } from 'utils/authUtils';
import axios, { AxiosInstance } from 'axios';

import { siteConfig } from 'constant/config';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: siteConfig.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const user = await getUser();

    if (user && user.token) {
      config.headers.Authorization = `Token ${user.token}`;
    }

    return config;
  } catch (error) {
    console.error('Failed to get user:', error);
    return config;
  }
});

export default axiosInstance;
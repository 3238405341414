import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMurderAction, deleteMurderAction, postMurderAction, putMurderAction } from 'store/actions/murder';
import { murderStateType, murderType } from 'types/murder';

const initialState: murderStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const murderSlice = createSlice({
  name: 'murder',
  initialState,
  reducers: {
    clearMurderState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getMurderAction.fulfilled, (state, action: PayloadAction<{ data: murderType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'MurderAdded';
          state.loading = false;
          const newMurder = action.payload.data;
          state.data.push(newMurder);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postMurderAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.error = 'recordExist';
        }else{
          
          state.error = 'somethingWentWrong';
        }
        state.loading = false;
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'murderDeleted';
          const deletedMurderId = action.payload.data.id;
          state.data = state.data.filter((Murder:murderType) => Murder.id !== deletedMurderId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteMurderAction.rejected, (state, action: PayloadAction<any>) => {
          state.error = 'somethingWentWrong';
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'murderUpdated';
          state.loading = false;
          const updatedMurder = action.payload.data;
          const index = state.data.findIndex((Murder:murderType) => Murder.id === updatedMurder.id);
          if (index !== -1) {
            state.data[index] = updatedMurder;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearMurderState } = murderSlice.actions;
export default murderSlice.reducer;

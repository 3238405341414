import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { cityType } from 'types/city';

type CityAutocompleteProps = {
  data: cityType[];
  onChange: (value: number) => void;
  textFieldProps?: TextFieldProps; 
};

const CityAutocomplete: React.FC<CityAutocompleteProps> = ({ data, onChange, textFieldProps }) => {
  const { t, i18n } = useTranslation();

  const options = data.map(city => ({
    id: city.id,
    label: i18n.language === 'he' ? city.name_hebrew : city.name_arabic,
  }));

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      noOptionsText={t('commonTerms.noOptions')}
      onChange={(event, value) => {
        if (value) {
          onChange(value.id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default CityAutocomplete;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ageGroupType } from 'types/ageGroup';
import {putAgeGroupService} from 'services/ageGroup'

export const putAgeGroupAction = createAsyncThunk(
  'ageGroup/putAgeGroup',
  async (requestData:{id:number,data:ageGroupType}, thunkAPI) => {
    try {
      return await putAgeGroupService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
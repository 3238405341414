import { combineReducers } from 'redux';
import authReducer from './authReducer';
import regionReducer from './regionReducer';
import socialBackgroundSlice from './socialBackgroundReducer';
import ageGroupReducer from './ageGroupReducer';
import motiveReducer from './motiveReducer';
import timeOfMurderReducer from './timeOfMurderReducer';
import citySlice from './cityReducer'
import methodOfMurderSlice from './methodOfMurder'
import murderReducer from './murderReducer';
import ReligionSlice from './religionReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  region: regionReducer,
  socialBackground: socialBackgroundSlice,
  ageGroup: ageGroupReducer,
  motive: motiveReducer,
  timeOfMurder: timeOfMurderReducer,
  city: citySlice,
  methodOfMurder: methodOfMurderSlice,
  murder: murderReducer,
  religion:ReligionSlice
});


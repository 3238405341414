import { createAsyncThunk } from '@reduxjs/toolkit';
import { motiveType } from 'types/motive';
import {putMotiveService} from 'services/motive'

export const putMotiveAction = createAsyncThunk(
  'motive/putMotive',
  async (requestData:{id:number,data:motiveType}, thunkAPI) => {
    try {
      return await putMotiveService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import React from 'react';
import {
  Box,
  Typography,
  Button,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)({
  padding: '16px', 
});

const StyledButton = styled(Button)({
  margin: '8px', 
});


type DeleteConfirmationProps<T> = {
    target: T;
    message: string;
    onClose: () => void;
    onDelete: (id: string) => void;
  };
  
const DeleteConfirmation: React.FC<DeleteConfirmationProps<any>> = ({ target, message, onClose, onDelete }) => {
  const {t} = useTranslation();

  const approve = () => {
    onDelete(`${target.id}`)
  };

  return (
    <StyledBox>
      <Typography sx={{p:2}}>
        {message}
      </Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          marginTop: '16px',
        }}>
          <StyledButton onClick={onClose} color="info" variant="contained">
            {t('cancel')}
          </StyledButton>
          <StyledButton onClick={approve} color="error" variant="contained">
            {t('commonTerms.delete')}
          </StyledButton>
        </Box>
    </StyledBox>
  );
};

export default DeleteConfirmation;


import dayjs, { Dayjs } from 'dayjs';

export function formatDate(inputDate: string) {
  
  const dateObject = new Date(inputDate);
    
  if (isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }
  
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  
  return `${day}-${month}-${year}`;
}


export function formatDateToYYYYMMDD(date: any): string {
  if (!dayjs.isDayjs(date)) {
    throw new Error('Invalid date format. Expected a Dayjs object.');
  }

  return date.format('YYYY-MM-DD');
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cityStateType, cityType } from 'types/city';
import { deleteCityAction, getCityAction, postCityAction, putCityAction } from 'store/actions/city';

const initialState: cityStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    clearCityState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getCityAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getCityAction.fulfilled, (state, action: PayloadAction<{ data: cityType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getCityAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postCityAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postCityAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'cityAdded';
          state.loading = false;
          const newCity = action.payload.data;
          state.data.push(newCity);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postCityAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteCityAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCityAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'cityDeleted';
          const deletedCityId = action.payload.data.id;
          state.data = state.data.filter((city) => city.id !== deletedCityId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteCityAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteCity';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putCityAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putCityAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'cityUpdated';
          state.loading = false;
          const updatedCity = action.payload.data;
          const index = state.data.findIndex((city) => city.id === updatedCity.id);
          if (index !== -1) {
            state.data[index] = updatedCity;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putCityAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearCityState } = citySlice.actions;
export default citySlice.reducer;

import { AgeGroup, City, MethodOfMurder, Motive, Murder, Region, SocialBackground, TimeOfMurder, LoginPage, NotFound, Religion, Chart } from 'pages';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GavelIcon from '@mui/icons-material/Gavel';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PeopleIcon from '@mui/icons-material/People';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LanguageIcon from '@mui/icons-material/Language';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';

import { Route} from 'react-router-dom';

import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';


export const routesArray = [
    {
        path: '/',
        element: <PrivateRoute><Murder /></PrivateRoute>,
        icon: <FormatListNumberedIcon />,
        label: "MurderPage",
        nav: true,
        requires_superuser:false,
    },
    {
      path: '/chart',
      element: <PrivateRoute><Chart /></PrivateRoute>,
      label: "chart",
      nav: false,
      requires_superuser:false,
    },
    {
        path: '/login',
        element: <PublicRoute><LoginPage /></PublicRoute>,
        icon: <PersonIcon />,
        label: 'Login',
        nav: false,
        requires_superuser:false,
    },
    {
        path: '/age-groups',
        element: <PrivateRoute><AgeGroup /></PrivateRoute>,
        icon: <PeopleIcon />,
        label: "ageGroupPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/addresses',
        element: <PrivateRoute><City /></PrivateRoute>,
        icon: <LocationOnIcon />,
        label: "cityPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/methods-of-murder',
        element: <PrivateRoute><MethodOfMurder /></PrivateRoute>,
        icon: <MenuBookIcon />,
        label: "methodOfMurderPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/motives',
        element: <PrivateRoute><Motive /></PrivateRoute>,
        icon: <GavelIcon />,
        label: "MotivesPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/regions',
        element: <PrivateRoute><Region /></PrivateRoute>,
        icon: <LanguageIcon />,
        label: "regionPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/social-background',
        element: <PrivateRoute><SocialBackground /></PrivateRoute>,
        icon: <FamilyRestroomIcon />,
        label: "socialBackgroundPage",
        nav: true,
        requires_superuser:true,
    },
    {
        path: '/times-of-murder',
        element: <PrivateRoute><TimeOfMurder /></PrivateRoute>,
        icon: <ScheduleIcon />,
        label: "timeOfMurderPage",
        nav: true,
        requires_superuser:true,
    },
    {
      path: '/religion',
      element: <PrivateRoute><Religion /></PrivateRoute>,
      icon: <SelfImprovementIcon />,
      label: "religions",
      nav: true,
      requires_superuser:true,
    },
    {
        path: '*',
        element: <NotFound />,
        label: 'Not Found',
        nav: false,
        requires_superuser:false,
    },
];


export const renderedRoutes = (is_superuser: boolean = false) => (
  routesArray.map((route, index) => (
    (is_superuser && route.requires_superuser) || !route.requires_superuser
      ? <Route key={index} path={route.path} element={route.element} />
      : null
  ))
);


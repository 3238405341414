import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteSocialBackgroundService} from 'services/socialBackground'

export const deleteSocialBackgroundAction = createAsyncThunk(
  'socialBackground/deleteSocialBackground',
  async (id:string , thunkAPI) => {
    try {
      return await deleteSocialBackgroundService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
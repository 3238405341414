import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

interface LoaderProps {
  loading: boolean
}
export default function Loader({ loading }: LoaderProps) {
  return (
    <Backdrop style={{
      zIndex: 9999, background: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(3px)',
    }} open={loading}>
      <CircularProgress color='primary' />
    </Backdrop>
  )
}

import React from 'react';
import { Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const Emoji = styled('span')({
  fontSize: '4rem',
  marginBottom: '1rem',
  display: 'block',
});

const NotFound = () => {
  const {t} = useTranslation();
  return (
    <NotFoundContainer>
      <Emoji role="img" aria-label="sad-face">
        😢
      </Emoji>
      <Typography variant="h4" gutterBottom>
        {t('PageNotFound')}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {t('PageNotFoundDescription')}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        {t('goBack')}
      </Button>
    </NotFoundContainer>
  );
};

export default NotFound;

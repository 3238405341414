import axiosInstance from 'services/axiosInstance'


export const deleteReligionService = async (id:string) => {
  const response = await axiosInstance.delete(`/religions/${id}`)
  
  return response;
};



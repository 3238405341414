import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import * as yup from 'yup';
import { CustomButton, CustomTextField } from 'components/common';
import { useTranslation } from 'react-i18next';
import { regionFormType, regionType } from 'types/region';

interface RegionFormProps {
  data?: regionType;
  onSubmit: (formData: regionFormType | regionType | any) => void;
  title: string;
  onClose: () => void;
  type:'add'|'edit'
}

const RegionForm: React.FC<RegionFormProps> = ({ data, onSubmit, title, onClose,type }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<regionFormType | any>({
    id: '',
    name_hebrew: '',
    name_arabic: '',
    number: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  const schema = yup.object().shape({
    name_hebrew: yup.string().required(t('areaNameIsRequierd')),
    name_arabic: yup.string(),
    number: yup.string().required(t('areaNumberIsRequierd')),
  });

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id || '',
        name_hebrew: data.name_hebrew || '',
        name_arabic: data.name_arabic || '',
        number: data.number || '',
      });
    }
  }, [data]);

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [field]: e.target.value });
    setIsDataChanged(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await schema.validate(formData, { abortEarly: false });
      onSubmit(formData);
      onClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        error.inner.forEach((e) => {
          validationErrors[e.path!] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography variant="h5">{title}</Typography>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Box sx={{ px: 2 }}>
            <CustomTextField
              label={t('Name(Hebrew)')}
              value={formData.name_hebrew}
              onChange={handleChange('name_hebrew')}
              error={!!errors.name_hebrew}
              helperText={errors.name_hebrew}
              size="small"
              sx={{
                m: 2,
              }}
            />

            <CustomTextField
              label={t('Name(Arabic)')}
              value={formData.name_arabic}
              onChange={handleChange('name_arabic')}
              error={!!errors.name_arabic}
              helperText={errors.name_arabic}
              size="small"
              sx={{
                m: 2,
              }}
            />
          </Box>

          <Box sx={{ px: 2 }}>
            <CustomTextField
              label={t('areaNumber')}
              value={formData.number}
              onChange={handleChange('number')}
              error={!!errors.number}
              helperText={errors.number}
              size="small"
              sx={{
                m: 2,
              }}
            />
          </Box>
        </Box>

        <Box sx={{ alignSelf: 'center', p: 2 }}>
          <CustomButton
            onClick={onClose}
            variant="contained"
            color="info"
            size="large"
            sx={{ mx: 1 }}
            label={t('cancel')}
          />

          <CustomButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mx: 1 }}
            label={type === 'add'? t('add') : t('commonTerms.update')}
            disabled={!isDataChanged}
          />
        </Box>
      </Box>
    </form>
  );
};

export default RegionForm;

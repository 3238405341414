import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from 'store/store';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme, ThemeProvider} from '@mui/material'

import { arSA, faIR } from '@mui/material/locale';

import i18n from 'locale/i18n'
import { I18nextProvider } from "react-i18next";

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({direction: 'rtl' })
  

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <React.StrictMode>
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={createTheme({ ...theme}, faIR)}>
                  <App />
              </ThemeProvider>
          </CacheProvider>
          </React.StrictMode>
        </BrowserRouter>
      </Provider>
  </I18nextProvider>,
  document.getElementById("root"),
);
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
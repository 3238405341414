import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ageGroupStateType, ageGroupType } from 'types/ageGroup';
import { deleteAgeGroupAction, getAgeGroupAction, postAgeGroupAction, putAgeGroupAction } from 'store/actions/ageGroup';

const initialState: ageGroupStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const ageGroupSlice = createSlice({
  name: 'ageGroup',
  initialState,
  reducers: {
    clearAgeGroupState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getAgeGroupAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getAgeGroupAction.fulfilled, (state, action: PayloadAction<{ data: ageGroupType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getAgeGroupAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postAgeGroupAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postAgeGroupAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'ageGroupAdded';
          state.loading = false;
          const newAgeGroup = action.payload.data;
          state.data.push(newAgeGroup);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postAgeGroupAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteAgeGroupAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAgeGroupAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'ageGroupDeleted';
          const deletedAgeGroupId = action.payload.data.id;
          state.data = state.data.filter((ageGroup:ageGroupType) => ageGroup.id !== deletedAgeGroupId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteAgeGroupAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteAgeGroup';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putAgeGroupAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putAgeGroupAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'ageGroupUpdated';
          state.loading = false;
          const updatedAgeGroup = action.payload.data;
          const index = state.data.findIndex((ageGroup:ageGroupType) => ageGroup.id === updatedAgeGroup.id);
          if (index !== -1) {
            state.data[index] = updatedAgeGroup;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putAgeGroupAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearAgeGroupState } = ageGroupSlice.actions;
export default ageGroupSlice.reducer;

import { murderType } from "types/murder";
import { MRT_ColumnDef } from "material-react-table";
import { formatDate } from "utils/date";
import { Cell } from "components/common/cell";
import dayjs from 'dayjs';

export const createColumns = (t: any, i18n: any): MRT_ColumnDef<murderType>[] => {
    return [
          {
            header: t('fields.date_of_murder'),
            accessorFn: (originalRow) =>  dayjs(originalRow.date_of_murder).format('DD/MM/YYYY'),
            size: 50,
            accessorKey: 'date_of_murder',
            filterVariant: 'date-range',
            Cell: ({ cell }) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('commonTerms.day'),
            accessorFn: (originalRow) => dayjs(originalRow.date_of_murder).format('DD/MM/YYYY').split('/')[0],
            size: 50,
            accessorKey: 'day',
            filterVariant: 'text',
            Cell: ({ cell }) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('commonTerms.month'),
            accessorFn: (originalRow) => dayjs(originalRow.date_of_murder).format('DD/MM/YYYY').split('/')[1],
            size: 50,
            accessorKey: 'month',
            filterVariant: 'text',
            Cell: ({ cell }) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('commonTerms.year'),
            size: 50,
            accessorFn: (originalRow) => dayjs(originalRow.date_of_murder).format('DD/MM/YYYY').split('/')[2],
            accessorKey: 'year',
            filterVariant: 'multi-select',
            Cell: ({ cell }) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.last_name'),
            size: 50,
            accessorKey: 'last_name',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.first_name'),
            size: 50,
            accessorKey: 'first_name',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.city'),
            size: 50,
            accessorKey: 'city.name_hebrew',
            filterVariant: 'multi-select',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.time_of_murder'),
            size: 50,
            accessorKey: i18n.language === 'ar' ? 'time_of_murder.name_arabic':'time_of_murder.name_hebrew',
            filterVariant: 'multi-select',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.age'),
            accessorKey: 'age',
            size: 50,
            columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'],
            filterFn: 'between',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.gender'),
            accessorKey: 'gender',
            size: 50,
            filterVariant: 'multi-select',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={ t(cell.getValue())} />
          },
          {
            header: t('fields.method_of_murder'),
            accessorKey: i18n.language === 'ar' ? 'method_of_murder.name_arabic':'method_of_murder.name_hebrew',
            filterVariant: 'multi-select',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.notes'),
            accessorKey: 'notes',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.region'),
            accessorKey: 'region.number',
            size: 50,
            //filterVariant: 'multi-select',
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.details'),
            accessorKey: 'details',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.religion'),
            accessorKey: i18n.language === 'ar' ? 'religion.name_arabic':'religion.name_hebrew',
            filterVariant: 'multi-select',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.motive_of_murder'),
            accessorKey: i18n.language === 'ar' ? 'motive_of_murder.name_arabic':'motive_of_murder.name_hebrew',
            filterVariant: 'multi-select',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.age_group'),
            accessorKey: 'age_group.name',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.social_background'),
            accessorKey: i18n.language === 'ar' ? 'social_background.name_arabic':'social_background.name_hebrew',
            filterVariant: 'multi-select',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('fields.career'),
            accessorKey:'career',
            filterVariant: 'multi-select',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue()} />
          },
          {
            header: t('commonTerms.Created_By'),
            accessorKey: 'created_by.username',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? cell.getValue() : ''} />
          },
          {
            header: t('commonTerms.Updated_By'),
            accessorKey: 'updated_by.username',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? cell.getValue() : ''} />
          },
          {
            header: t('commonTerms.Created_At'),
            accessorKey: 'created_at',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? formatDate(cell.getValue()) : ''} />
          },
          {
            header: t('commonTerms.Updated_At'),
            accessorKey: 'updated_at',
            size: 50,
            Cell: ({ cell }: any) => <Cell style={{ fontSize: '1rem' }} value={cell.getValue() ? formatDate(cell.getValue()) : ''} />,
          },
    ];
  };
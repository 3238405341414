import { createAsyncThunk } from '@reduxjs/toolkit';
import {getMurderService} from 'services/murder'

export const getMurderAction = createAsyncThunk(
  'Murder/getMurders',
  async (_ , thunkAPI) => {
    try {
      return await getMurderService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
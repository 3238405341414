import { murderType } from "types/murder";

interface ChartData {
    labels: string[];
    datasets:any;
}

export enum ChartType {
    BAR = 'bar',
    PIE = 'pie',
    LINE = 'line',
    DOUGHNUT = 'doughnut'
}

export const generateChartData = (data: murderType[] | any, selectedKey: keyof murderType | 'month' | 'year', chartType:string = 'doughnut'): ChartData => {
    if (selectedKey === 'date_of_murder') {
        if (chartType === 'polarArea' || chartType === 'doughnut') {
            const countsMap = new Map<string, number>();

            data.forEach((murder:any) => {
                const date = new Date(murder[selectedKey] as string);
                const year = date.getFullYear().toString();
                const cityYear = `${murder['city']} (${year})`;
                countsMap.set(cityYear, (countsMap.get(cityYear) || 0) + 1);
            });

            const labels: string[] = Array.from(countsMap.keys());
            const datasets = [{
                label: 'Murders',
                data: Array.from(countsMap.values()),
                
            }];

            return { labels, datasets };
        } else {
            const countsMap = new Map<string, number[]>();

            const citiesYears = new Set<string>();
            data.forEach((murder:any) => {
                const date = new Date(murder[selectedKey] as string);
                const year = date.getFullYear().toString();
                const cityYear = `(${year}) ${murder['city']} `; 
                citiesYears.add(cityYear);
            });

            const sortedCitiesYears = Array.from(citiesYears).sort();

            sortedCitiesYears.forEach((cityYear) => {
                countsMap.set(cityYear, Array(12).fill(0));
            });

            data.forEach((murder:any) => {
                const date = new Date(murder[selectedKey] as string);
                const year = date.getFullYear().toString();
                const month = date.getMonth();
                const cityYear = `(${year}) ${murder['city']} `
                const count = countsMap.get(cityYear)!;
                count[month] += 1; 
                countsMap.set(cityYear, count);
            });

            const labels: string[] = Array.from({ length: 12 }, (_, i) => `${i + 1}`);
            const datasets = Array.from(countsMap.entries()).map(([cityYear, counts], index) => {
                return { 
                    label: cityYear,
                    data: counts,
                    fill: true
                };
            });

            return { labels, datasets };
        }
    
    } else if (selectedKey === 'month' || selectedKey === 'year') {
        const countsMap = new Map<string, number>();

        data.forEach((murder:any) => {
            const keyValue = murder[selectedKey] as string;
            if (countsMap.has(keyValue)) {
                countsMap.set(keyValue, countsMap.get(keyValue)! + 1);
            } else {
                countsMap.set(keyValue, 1);
            }
        });

        const labels: string[] = [];
        const datasetData: number[] = [];
        countsMap.forEach((count, label) => {
            labels.push(label);
            datasetData.push(count); 
        });

        const datasets = [{ 
            label: 'Murders',
            data: datasetData,
        }];

        return { labels, datasets };
    }
    else {
        const countsMap = new Map<string, number>();

        data.forEach((murder:any) => {
            const keyValue = murder[selectedKey] as string;
            if (countsMap.has(keyValue)) {
                countsMap.set(keyValue, countsMap.get(keyValue)! + 1);
            } else {
                countsMap.set(keyValue, 1);
            }
        });

        const labels: string[] = [];
        const datasetData: number[] = [];
        countsMap.forEach((count, label) => {
            labels.push(label);
            datasetData.push(count); 
        });

        const datasets = [{ 
            label: 'Murders',
            data: datasetData,
        }];

        return { labels, datasets };
    }
};

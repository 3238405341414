import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteMurderService} from 'services/murder'

export const deleteMurderAction = createAsyncThunk(
  'Murder/deleteMurder',
  async (id:string , thunkAPI) => {
    try {
      return await deleteMurderService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import { methodOfMurderType } from 'types/methodOfMurder';
import {putMethodOfMurderService} from 'services/methodOfMurder'

export const putMethodOfMurderAction = createAsyncThunk(
  'methodOfMurder/putMethodOfMurder',
  async (requestData:{id:number,data:methodOfMurderType}, thunkAPI) => {
    try {
      return await putMethodOfMurderService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
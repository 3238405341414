import { ChartTypeRegistry } from 'chart.js';
import { murderType } from 'types/murder';

export type KeyValue = {
    key: keyof murderType | 'month' | 'year';
    title: string;
    allowedChartTypes: (keyof ChartTypeRegistry)[];
};

export const murderTypeKeys: KeyValue[] = [
    { key: 'time_of_murder', title: 'fields.time_of_murder', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'month', title: 'commonTerms.month', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'year', title: 'commonTerms.year', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'city', title: 'fields.city', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'method_of_murder', title: 'fields.method_of_murder', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'region', title: 'fields.region', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'motive_of_murder', title: 'fields.motive_of_murder', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'social_background', title: 'fields.social_background', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'career', title: 'fields.career', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'age_group', title: 'fields.age_group', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'date_of_murder', title: 'fields.date_of_murder', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'gender', title: 'fields.gender', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
    { key: 'religion', title: 'fields.religion', allowedChartTypes: ['line', 'doughnut', 'bar', 'polarArea'] },
];

import { createAsyncThunk } from '@reduxjs/toolkit';
import {checkAuthService} from 'services/auth'

export const checkAuthAction = createAsyncThunk(
  'auth/checkAuth',
  async (_ , thunkAPI) => {
    try {
      return await checkAuthService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
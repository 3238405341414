import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteMotiveService} from 'services/motive'

export const deleteMotiveAction = createAsyncThunk(
  'motive/deleteMotive',
  async (id:string , thunkAPI) => {
    try {
      return await deleteMotiveService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { regionStateType, regionType } from 'types/region';
import { deleteRegionAction, getRegionAction, postRegionAction, putRegionAction } from 'store/actions/region';

const initialState: regionStateType = {
  loading: false,
  error: null,
  success: null,
  data: [],
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    clearRegionState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getRegionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getRegionAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getRegionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postRegionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postRegionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'regionAdded';
          state.loading = false;
          state.data.push(action.payload.data);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postRegionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteRegionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRegionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'regionDeleted';
          const deletedRegionId = action.payload.data.id;
          state.data = state.data.filter((region:regionType) => region.id !== deletedRegionId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteRegionAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteRegion';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putRegionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putRegionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'regionUpdated';
          state.loading = false;
          const updatedRegion = action.payload.data;
          const index = state.data.findIndex((region:regionType) => region.id === updatedRegion.id);
          if (index !== -1) {
            state.data[index] = updatedRegion;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putRegionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearRegionState } = regionSlice.actions;
export default regionSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { regionType } from 'types/region';
import {putRegionService} from 'services/region'

export const putRegionAction = createAsyncThunk(
  'region/putRegion',
  async (requestData:{id:number,data:regionType}, thunkAPI) => {
    try {
      return await putRegionService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
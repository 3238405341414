import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMethodOfMurderAction, deleteMethodOfMurderAction, postMethodOfMurderAction, putMethodOfMurderAction } from 'store/actions/methodOfMurder';
import { methodOfMurderStateType, methodOfMurderType } from 'types/methodOfMurder';

const initialState: methodOfMurderStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const methodOfMurderSlice = createSlice({
  name: 'methodOfMurder',
  initialState,
  reducers: {
    clearMethodOfMurderState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getMethodOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getMethodOfMurderAction.fulfilled, (state, action: PayloadAction<{ data: methodOfMurderType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getMethodOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postMethodOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMethodOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'methodOfMurderAdded';
          state.loading = false;
          const newMethodOfMurder = action.payload.data;
          state.data.push(newMethodOfMurder);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postMethodOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteMethodOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMethodOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'methodOfMurderDeleted';
          const deletedMethodOfMurderId = action.payload.data.id;
          state.data = state.data.filter((methodOfMurder:methodOfMurderType) => methodOfMurder.id !== deletedMethodOfMurderId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteMethodOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteMethodOfMurder';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putMethodOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putMethodOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'methodOfMurderUpdated';
          state.loading = false;
          const updatedMethodOfMurder = action.payload.data;
          const index = state.data.findIndex((methodOfMurder:methodOfMurderType) => methodOfMurder.id === updatedMethodOfMurder.id);
          if (index !== -1) {
            state.data[index] = updatedMethodOfMurder;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putMethodOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearMethodOfMurderState } = methodOfMurderSlice.actions;
export default methodOfMurderSlice.reducer;

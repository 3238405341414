import { createAsyncThunk } from '@reduxjs/toolkit';
import { religionType } from 'types/religion';
import {putReligionService} from 'services/religion'

export const putReligionAction = createAsyncThunk(
  'Religion/putReligion',
  async (requestData:{id:number,data:religionType}, thunkAPI) => {
    try {
      return await putReligionService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
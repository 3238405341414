import { createAsyncThunk } from '@reduxjs/toolkit';
import { regionFormType } from 'types/region';
import {postRegionService} from 'services/region'

export const postRegionAction = createAsyncThunk(
  'region/postRegion',
  async (data:regionFormType, thunkAPI) => {
    try {
      return await postRegionService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import React, { ReactNode } from 'react';
import { Modal, Fade, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledPaper = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4, 6),
  outline: 'none',
  overflowY: 'auto',
  maxHeight: '80vh',
  '&::before': {
    content: '""',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.2)', 
    backdropFilter: 'blur(2px)', 
    zIndex: -1,
  },
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  return (
    <StyledModal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <StyledPaper>
          <StyledCloseButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </StyledCloseButton>
          {children}
        </StyledPaper>
      </Fade>
    </StyledModal>
  );
};

export default CustomModal;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { timeOfMurderType } from 'types/timeOfMurder';
import {putTimeOfMurderService} from 'services/timeOfMurder'

export const putTimeOfMurderAction = createAsyncThunk(
  'timeOfMurder/putTimeOfMurder',
  async (requestData:{id:number,data:timeOfMurderType}, thunkAPI) => {
    try {
      return await putTimeOfMurderService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
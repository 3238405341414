import { createAsyncThunk } from '@reduxjs/toolkit';
import {getRegionService} from 'services/region'

export const getRegionAction = createAsyncThunk(
  'region/getRegions',
  async (_ , thunkAPI) => {
    try {
      return await getRegionService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
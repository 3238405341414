import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteAgeGroupService} from 'services/ageGroup'

export const deleteAgeGroupAction = createAsyncThunk(
  'ageGroup/deleteAgeGroup',
  async (id:string , thunkAPI) => {
    try {
      return await deleteAgeGroupService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
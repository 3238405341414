import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMotiveAction, deleteMotiveAction, postMotiveAction, putMotiveAction } from 'store/actions/motive';
import { motiveStateType, motiveType } from 'types/motive';

const initialState: motiveStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const motiveSlice = createSlice({
  name: 'motive',
  initialState,
  reducers: {
    clearMotiveState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getMotiveAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getMotiveAction.fulfilled, (state, action: PayloadAction<{ data: motiveType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getMotiveAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postMotiveAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMotiveAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'motiveAdded';
          state.loading = false;
          const newMotive = action.payload.data;
          state.data.push(newMotive);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postMotiveAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteMotiveAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMotiveAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'motiveDeleted';
          const deletedMotiveId = action.payload.data.id;
          state.data = state.data.filter((motive:motiveType) => motive.id !== deletedMotiveId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteMotiveAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteMotive';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putMotiveAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putMotiveAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'motiveUpdated';
          state.loading = false;
          const updatedMotive = action.payload.data;
          const index = state.data.findIndex((motive:motiveType) => motive.id === updatedMotive.id);
          if (index !== -1) {
            state.data[index] = updatedMotive;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putMotiveAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearMotiveState } = motiveSlice.actions;
export default motiveSlice.reducer;

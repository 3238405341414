// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import arTranslation from './ar.json';
import heTranslation from './he.json';

const resources = {
  ar: {
    translation: arTranslation,
  },
  he: {
    translation: heTranslation,
  },
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: 'ar',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { cityFormType } from 'types/city';
import {postCityService} from 'services/city'

export const postCityAction = createAsyncThunk(
  'city/postCity',
  async (data:cityFormType, thunkAPI) => {
    try {
      return await postCityService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { socialBackgroundStateType, socialBackgroundType } from 'types/socialBackground';
import {
  postSocialBackgroundAction,
  putSocialBackgroundAction,
  deleteSocialBackgroundAction,
  getSocialBackgroundAction,
} from 'store/actions/socialBackground';

const initialState: socialBackgroundStateType = {
  loading: false,
  error: null,
  success: null,
  data: [],
};

export const socialBackgroundSlice = createSlice({
  name: 'socialBackground',
  initialState,
  reducers: {
    clearSocialBackgroundState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getSocialBackgroundAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getSocialBackgroundAction.fulfilled, (state, action: PayloadAction<{ data: socialBackgroundType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getSocialBackgroundAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postSocialBackgroundAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postSocialBackgroundAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'socialBackgroundAdded';
          state.loading = false;
          const newSocialBackground = action.payload.data;
          state.data.push(newSocialBackground);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postSocialBackgroundAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteSocialBackgroundAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSocialBackgroundAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'socialBackgroundDeleted';
          const deletedSocialBackgroundId = action.payload.data.id;
          state.data = state.data.filter(
            (socialBackground:socialBackgroundType) => socialBackground.id !== deletedSocialBackgroundId
          );
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteSocialBackgroundAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteSocialBackground';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putSocialBackgroundAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putSocialBackgroundAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'socialBackgroundUpdated';
          state.loading = false;
          const updatedSocialBackground = action.payload.data;
          const index = state.data.findIndex(
            (socialBackground:socialBackgroundType) => socialBackground.id === updatedSocialBackground.id
          );
          if (index !== -1) {
            state.data[index] = updatedSocialBackground;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putSocialBackgroundAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearSocialBackgroundState } = socialBackgroundSlice.actions;
export default socialBackgroundSlice.reducer;

import { styled, TextField } from '@mui/material';

export const CustomTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset > legend": {
      fontSize: '1.25rem',
    },
  },
  margin: '10px',
});
import { createAsyncThunk } from '@reduxjs/toolkit';
import { murderType } from 'types/murder';
import {putMurderService} from 'services/murder'

export const putMurderAction = createAsyncThunk(
  'Murder/putMurder',
  async (requestData:{id:string,data:murderType}, thunkAPI) => {
    try {
      return await putMurderService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
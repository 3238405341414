import { createAsyncThunk } from '@reduxjs/toolkit';
import { religionFormType } from 'types/religion';
import {postReligionService} from 'services/religion'

export const postReligionAction = createAsyncThunk(
  'religion/postReligion',
  async (data:religionFormType, thunkAPI) => {
    try {
      return await postReligionService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import React from 'react';
import { Box, Typography, Avatar, Alert } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CustomTextField, CustomButton } from 'components/common';
import { authActionType } from 'types/auth';
import styled from '@emotion/styled';

interface LoginFormProps {
  onSubmit: (data: authActionType) => void;
  loading: boolean;
  error: string | null;
}

const StyledAlert = styled(Alert)({
  fontSize: '1.2rem',
});

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, loading, error }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    username: Yup.string().required(t('username_required')),
    password: Yup.string().required(t('password_required')),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '2rem',
        maxWidth: '400px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('login')}
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        {error && (
        <StyledAlert severity="error">{t(error)}</StyledAlert>
        )}
        <CustomTextField
          required
          fullWidth
          id="username"
          label={t('username')}
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <CustomTextField
          required
          fullWidth
          name="password"
          label={t('password')}
          type="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          label={t('login')}
          disabled={loading}
        />
        
      </Box>
    </Box>
  );
};

export default LoginForm;

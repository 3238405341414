import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteReligionService} from 'services/religion'

export const deleteReligionAction = createAsyncThunk(
  'religion/deleteReligion',
  async (id:string , thunkAPI) => {
    try {
      return await deleteReligionService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
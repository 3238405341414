import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, MenuItem, Select, SelectChangeEvent, Paper, Grid, FormControl, InputLabel, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    ChartType,
    //countObjectsByLabel, 
    generateChartData
} from 'utils/chart';
import { CustomChart } from 'components/common/customChart';
import { useTranslation } from 'react-i18next';
import { murderType } from 'types/murder';
import { ChartTypeRegistry } from 'chart.js';
import SaveIcon from '@mui/icons-material/Save';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { KeyValue, murderTypeKeys } from 'constant/chartKey';

const Chart: React.FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data || [];
    const [title, setTitle] = useState<string>('');
    const [selectedKey, setSelectedKey] = useState<KeyValue | null>(null);
    const [selectedGraph, setSelectedGraph] = useState<keyof ChartTypeRegistry | null>(null);
    const [chartData, setChartData] = useState<any>();
    
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleData = async () => {
        const testData = generateChartData(data, selectedKey?.key!, selectedGraph!)
        setChartData(testData)
    };

    useEffect(() => {
        if (data.length === 0) {
            handleBackClick();
        }
    }, []);

    useEffect(() => {
        setChartData(null);
    }, [title, selectedKey, selectedGraph]);

    const handleKeyChange = (event: SelectChangeEvent<string>) => {
        const selectedKeyValue = murderTypeKeys.find(keyValue => keyValue.key === event.target.value);
        setSelectedKey(selectedKeyValue || null);
    };


    const handleGraphChange = (event: SelectChangeEvent<keyof ChartTypeRegistry>) => {
        setSelectedGraph(event.target.value as keyof ChartTypeRegistry);
    };

    const handleDownloadClick = async () => {
        const element =
            document.getElementById('chartCanvas');
        if (!element) return;
        const currentDate = new Date();
        const filename = `image_${currentDate.toISOString()}.png`;
        const canvas = await html2canvas(element);
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, filename, 'image/png');

    }

    return (
        <Box p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                }}
                my={4}>
                <Button variant='contained' size='large' onClick={handleBackClick} startIcon={<ArrowForwardIcon />} sx={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    opacity: 0.9,
                }}>
                    {t('commonTerms.back')}
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 'bold' }} mb={4}>
                    {t('chart.chartPage')}
                </Typography>
            </Box>

            <Box
                sx={{
                    alignSelf: 'center',
                    width: '100%',
                }}
            >
                <Box my={2}>
                    <Paper elevation={3}>
                        <Box sx={{ display: 'flex', gap: '.5rem', p: 2, flexDirection: 'column' }}>
                            <Grid container spacing={1} mt={2}>
                                <Grid item xs={2} my={1}>
                                    <FormControl fullWidth>
                                        <TextField id="chartTitle" label={t('chart.chartTitle')} variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} my={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="ChartKey">{t('chart.chartKey')}</InputLabel>
                                        <Select labelId='ChartKey' id='ChartKey' label={t('chart.chartKey')} value={selectedKey ? selectedKey.key : ''} onChange={handleKeyChange}>
                                            {murderTypeKeys.map((keyValue, index) => (
                                                <MenuItem key={index} value={keyValue.key}>
                                                    {t(keyValue.title)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} my={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="chartType">{t('chart.chartType')}</InputLabel>
                                        <Select labelId='chartType' id='chartType' label={t('chart.chartType')} value={selectedGraph!} onChange={handleGraphChange} variant="outlined" disabled={!selectedKey}>
                                            {
                                                selectedKey?.allowedChartTypes.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={2}>
                                <Grid item xs={2} my={1}>
                                    <FormControl>
                                        <Button variant='contained' color="success" size='large' disabled={selectedGraph === null || selectedGraph === null} onClick={handleData} sx={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            opacity: 0.9,
                                        }}>
                                            {t('chart.generate')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>



                {chartData && (
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', p: 3 }}>
                        <Paper elevation={3}>
                            <Box sx={{ width: '100%', height: '100%', m: 2, display: 'flex', alignItems: 'flex-end' }}>
                                <Button variant='contained' size='large' color='secondary' startIcon={<SaveIcon />} onClick={handleDownloadClick} >
                                    {t('chart.saveAsImage')}
                                </Button>
                            </Box>
                            <Box sx={{ width: '100%', height: '100%', m: 2 }}>
                                <CustomChart chartData={chartData.datasets!} labels={chartData.labels!} type={selectedGraph!} title={title} />
                            </Box>
                        </Paper>
                    </Box>
                )}

            </Box>
        </Box>
    );
};

export default Chart;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { methodOfMurderFormType } from 'types/methodOfMurder';
import {postMethodOfMurderService} from 'services/methodOfMurder'

export const postMethodOfMurderAction = createAsyncThunk(
  'methodOfMurder/postMethodOfMurder',
  async (data:methodOfMurderFormType, thunkAPI) => {
    try {
      return await postMethodOfMurderService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
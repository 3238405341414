import LoginForm from 'components/login/loginForm'
import { Box } from '@mui/material';
import {useAppDispatch,useAppSelector } from 'store/store';
import {loginAction} from 'store/actions/auth'
import {authActionType} from 'types/auth'

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const {loading, error} = useAppSelector(state => state.store.auth)

  const handleSubmit = (data:authActionType) => {
    dispatch(loginAction(data))
  };

  return (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <LoginForm loading={loading} error={error} onSubmit={handleSubmit}/>
    </Box>
  );
}



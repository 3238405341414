import { createAsyncThunk } from '@reduxjs/toolkit';
import {getSocialBackgroundService} from 'services/socialBackground'

export const getSocialBackgroundAction = createAsyncThunk(
  'socialBackground/getSocialBackgrounds',
  async (_ , thunkAPI) => {
    try {
      return await getSocialBackgroundService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
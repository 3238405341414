import { createAsyncThunk } from '@reduxjs/toolkit';
import { socialBackgroundFormType } from 'types/socialBackground';
import {postSocialBackgroundService} from 'services/socialBackground'

export const postSocialBackgroundAction = createAsyncThunk(
  'socialBackground/postRegion',
  async (data:socialBackgroundFormType, thunkAPI) => {
    try {
      return await postSocialBackgroundService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
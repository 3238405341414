import { createAsyncThunk } from '@reduxjs/toolkit';
import { motiveFormType } from 'types/motive';
import {postMotiveService} from 'services/motive'

export const postMotiveAction = createAsyncThunk(
  'motive/postMotive',
  async (data:motiveFormType, thunkAPI) => {
    try {
      return await postMotiveService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateLanguage } from 'utils/language';

interface LanguageSwitchProps {
  onLanguageChange: (newLanguage: string) => void;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ onLanguageChange }) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  const handleLanguageChange = (event: React.ChangeEvent<unknown>, newLanguage: string | null) => {
    if (newLanguage !== null) {
      updateLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      setSelectedLanguage(newLanguage);
      onLanguageChange(newLanguage);
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedLanguage}
      exclusive
      onChange={handleLanguageChange}
      size="large"
      sx={{
        marginBottom: '2rem',
        height: '3rem',
        position: 'relative',
        '& .MuiToggleButton-root': {
          height: '100%',
          width: '50%', 
          borderRadius: '4px',
          border: '0.5px solid #ccc',
          '&:hover': {
            borderColor: '#ccc',
          },
          '&.Mui-selected': {
            borderColor: '#ccc',
            backgroundColor:  'rgba(39, 133, 245, 0.33)',
            fontSize: '2rem',
            color: '#000', 
            '&:hover': {
              borderColor: '#ccc',
              backgroundColor:  'rgba(39, 133, 245, 0.33)', 
            },
          },
        },
      }}
    >
      <ToggleButton value="he">
        <Typography variant="h6">{t('commonTerms.hebrew')}</Typography>
      </ToggleButton>
      <ToggleButton value="ar">
        <Typography variant="h6">{t('commonTerms.arabic')}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LanguageSwitch;

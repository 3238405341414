import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutService } from 'services/auth';

export const logoutAction = createAsyncThunk(
    'auth/logout',
    async (_, thunkAPI) => {
      try {
        return await logoutService();
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response)
      }
    },
  );
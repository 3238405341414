import i18n from 'locale/i18n'

export const setLanguage = () => {
    const storedLanguage = localStorage.getItem('lang');
  
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      const defaultLanguage = 'ar'; 
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem('lang', defaultLanguage);
    }
};

export const updateLanguage=(language:string)=>{
  localStorage.setItem('lang', language);
}
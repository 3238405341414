import { createAsyncThunk } from '@reduxjs/toolkit';
import { timeOfMurderFormType } from 'types/timeOfMurder';
import {postTimeOfMurderService} from 'services/timeOfMurder'

export const postTimeOfMurderAction = createAsyncThunk(
  'timeOfMurder/postTimeOfMurder',
  async (data:timeOfMurderFormType, thunkAPI) => {
    try {
      return await postTimeOfMurderService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
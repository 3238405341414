import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteTimeOfMurderService} from 'services/timeOfMurder'

export const deleteTimeOfMurderAction = createAsyncThunk(
  'timeOfMurder/deleteTimeOfMurder',
  async (id:string , thunkAPI) => {
    try {
      return await deleteTimeOfMurderService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
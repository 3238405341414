import { createAsyncThunk } from '@reduxjs/toolkit';
import {getCityService} from 'services/city'

export const getCityAction = createAsyncThunk(
  'city/getCity',
  async (_ , thunkAPI) => {
    try {
      return await getCityService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
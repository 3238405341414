import React, { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import {
  deleteRegionAction,
  getRegionAction,
  postRegionAction,
  putRegionAction,
} from 'store/actions/region';
import Loader from 'components/common/loader';
import { RegionTable, RegionForm } from 'components/region';
import { toast } from 'react-toastify';
import { clearRegionState } from 'store/reducers/regionReducer';
import CustomModal from 'components/common/modal';
import { Box, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { regionFormType, regionType } from 'types/region';

const Region: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, error, data, success } = useAppSelector(
    (state) => state.store.region
  );
  const [open, setOpen] = useState(false);

  const handleOpenNewRecordForm = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseNewRecordForm = useCallback(() => {
    setOpen(false);
  }, []);

  const onPost = (formData: regionFormType) => {
    dispatch(postRegionAction(formData));
  };

  const onDelete = useCallback(
    (id: string) => {
      dispatch(deleteRegionAction(id));
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (data: regionType) => {
      const formData = {
        id: data.id,
        data: data,
      };

      dispatch(putRegionAction(formData));
    },
    [dispatch]
  );

  useEffect(() => {
    if (error) {
      toast.error(t(error));
    }
    dispatch(clearRegionState());
  }, [error, dispatch, t]);

  useEffect(() => {
    if (success) {
      toast.success(t(success));
      handleCloseNewRecordForm();
      dispatch(clearRegionState());
    }
  }, [success, dispatch, t, handleCloseNewRecordForm]);

  useEffect(() => {
    dispatch(getRegionAction());

    return () => {
    };
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Box p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{fontWeight:'bold'}} mb={4}>
              {t('regionPage')}
            </Typography>

            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpenNewRecordForm}
              sx={{
                position:'fixed',
                top: '10%',
                right: '2%',
                zIndex: 1000
               }}
            >
              <AddIcon />
            </Fab>
          </Box>

          <Box
            sx={{
              alignSelf: 'center',
              width: '100%',
            }}
          >
            <RegionTable
              data={data}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          </Box>
          <CustomModal open={open} onClose={handleCloseNewRecordForm}>
            <RegionForm
              onSubmit={onPost}
              title={t('addRegion')}
              onClose={handleCloseNewRecordForm}
              type='add'
            />
          </CustomModal>
        </Box>
      )}
    </>
  );
};

export default Region;

import React, { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import {
  deleteMurderAction,
  getMurderAction,
  postMurderAction,
  putMurderAction,
} from 'store/actions/murder';
import Loader from 'components/common/loader';
import { toast } from 'react-toastify';
import CustomModal from 'components/common/modal';
import { Box, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { murderFormType, murderType } from 'types/murder';
import { MurderTable } from 'components/murder';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import MurderForm from 'components/murder/muderForm';
import { clearMurderState } from 'store/reducers/murderReducer';


const Murder: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { loading, error, data, success } = useAppSelector(
    (state) => state.store.murder
  );
  const { user } = useAppSelector(
    (state) => state.store.auth
  );

  const [open, setOpen] = useState(false);

  const handleOpenNewRecordForm = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseNewRecordForm = useCallback(() => {
    setOpen(false);
  }, []);

  const onCreate = (formData: murderFormType) => {
    dispatch(postMurderAction(formData));
    handleCloseNewRecordForm();
  };

  const onDelete = useCallback(
    (id: string) => {
      dispatch(deleteMurderAction(id));
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (data: murderType) => {
      const formData = {
        id: data.id,
        data: data,
      };

      dispatch(putMurderAction(formData));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getMurderAction());
    return () => {

    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(t(error));
    }

    if (success) {
      toast.success(t(success));
    }

    dispatch(clearMurderState());
  }, [error, success, dispatch, t]);

  return (
        <Box p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }} mb={4}>
              {t('MurderPage')}
            </Typography>

            {user && user.is_superuser ?
            
              <Fab
                color="primary"
                aria-label="add"
                onClick={handleOpenNewRecordForm}
               sx={{
                position:'fixed',
                top: '10%',
                right: '2%',
                zIndex: 1000
               }}
              >
                <AddIcon />
              </Fab>
              :
              null
            }
          </Box>

          <Box
            sx={{
              alignSelf: 'center',
              width: '100%',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
              <MurderTable
                data={data}
                onDelete={onDelete}
                onUpdate={onUpdate}
                is_superuser={user?.is_superuser}
                isLoading={loading}
              />
            </LocalizationProvider>
          </Box>
          <CustomModal open={open} onClose={handleCloseNewRecordForm}>
            <MurderForm
              onSubmit={onCreate}
              title={t('addMurder')}
              onClose={handleCloseNewRecordForm}
              type='add'
            />
          </CustomModal>
        </Box>
  );
};

export default Murder;

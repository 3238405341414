import { authActionType } from 'types/auth';
import axiosInstance from 'services/axiosInstance'


export const loginService = async (data:authActionType) => {
  const response = await axiosInstance.post('/login/', {
    username: data.username,
    password: data.password,
  });
  return response;
};

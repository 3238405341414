import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { religionStateType, religionType } from 'types/religion';
import { deleteReligionAction, getReligionAction, postReligionAction, putReligionAction } from 'store/actions/religion';

const initialState: religionStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const ReligionSlice = createSlice({
  name: 'religion',
  initialState,
  reducers: {
    clearReligionState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getReligionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getReligionAction.fulfilled, (state, action: PayloadAction<{ data: religionType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getReligionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postReligionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postReligionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'religionAdded';
          state.loading = false;
          const newReligion = action.payload.data;
          state.data.push(newReligion);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postReligionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteReligionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReligionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'religionDeleted';
          const deletedReligionId = action.payload.data.id;
          state.data = state.data.filter((Religion:religionType) => Religion.id !== deletedReligionId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteReligionAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteReligion';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putReligionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putReligionAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'religionUpdated';
          state.loading = false;
          const updatedReligion = action.payload.data;
          const index = state.data.findIndex((Religion:religionType) => Religion.id === updatedReligion.id);
          if (index !== -1) {
            state.data[index] = updatedReligion;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putReligionAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearReligionState } = ReligionSlice.actions;
export default ReligionSlice.reducer;

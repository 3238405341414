import { Box, Typography, TypographyProps } from "@mui/material";

type CellProps = {
    value?: any;
    style?: TypographyProps['sx'];
};

export const Cell = ({ value, style }: CellProps) => {
    const displayValue = value !== '' ? value : 'undefined';

    return (
        <Box sx={{
            textAlign: 'start',
        }}>
            <Typography sx={{
                fontSize: value !== '' ? '1.1rem' : '.5rem', 
                fontWeight: value !== '' ? '900' : '400' ,
                color: value !== '' ? 'inherit' : 'gray',
                ...style,
            }}>{displayValue}</Typography>
        </Box>
    );
};

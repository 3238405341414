import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsightsIcon from '@mui/icons-material/Insights';
import {reverseStringFn} from 'utils/chars'

import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { murderType } from 'types/murder';
import { MRT_Localization_AR } from 'material-react-table/locales/ar';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { createColumns } from './columns'
import CustomModal from 'components/common/modal';
import DeleteConfirmation from 'components/common/deleteConfirmation';
import MurderForm from './muderForm';

import { jsPDF } from 'jspdf';
import {font as arabicFont} from 'assets/fonts/NotoKufiArabic-Regular-normal'
import {font as hebrewFont} from 'assets/fonts/NotoSansHebrew-Regular-normal'

import autoTable from 'jspdf-autotable'

import dayjs from 'dayjs';

type MurderTableProps = {
  data: murderType[],
  onDelete: (id: string) => void,
  onUpdate: (data: murderType) => void,
  is_superuser: boolean | undefined,
  isLoading:boolean
}



const MurderTable = ({ data, onDelete, onUpdate,isLoading, is_superuser = false }: MurderTableProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [target, setTarget] = useState<murderType | null>(null);

  const handleOpenDeleteModal = (target: murderType) => {
    setTarget(target);
    setOpenDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDelete(false);
    setTarget(null);
  };

  const handleDeleteDone = (id: string) => {
    onDelete(id);
    handleCloseDeleteModal();
  };

  const handleOpenEditModal = (target: murderType) => {
    setTarget(target);
    setOpenEdit(true);
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
    setTarget(null);
  };

  const handleEditDone = () => {
    handleCloseEditModal();
  };

  const handleNavigateToChart = () => {
    navigate('/chart', { state: { data: simplifiedMurderData } });
  }

  const csvConfig = mkConfig({
    columnHeaders: [
      { displayLabel: t('commonTerms.ID'), key: 'id' },
      { displayLabel: t('fields.time_of_murder'), key: 'time_of_murder' },
      { displayLabel: t('fields.first_name'), key: 'first_name' },
      { displayLabel: t('fields.last_name'), key: 'last_name' },
      { displayLabel: t('fields.city'), key: 'city' },
      { displayLabel: t('fields.method_of_murder'), key: 'method_of_murder' },
      { displayLabel: t('fields.region'), key: 'region' },
      { displayLabel: t('fields.motive_of_murder'), key: 'motive_of_murder' },
      { displayLabel: t('fields.social_background'), key: 'social_background' },
      { displayLabel: t('fields.age_group'), key: 'age_group' },
      { displayLabel: t('fields.date_of_murder'), key: 'date_of_murder' },
      { displayLabel: t('fields.age'), key: 'age' },
      { displayLabel: t('fields.gender'), key: 'gender' },
      { displayLabel: t('fields.notes'), key: 'notes' },
      { displayLabel: t('fields.details'), key: 'details' },
      { displayLabel: t('fields.religion'), key: 'religion' },
      { displayLabel: t('fields.career'), key: 'career' }
    ]
  });

  const handleExportDataCSV = () => {
    const timestamp = new Date().toISOString().replace(/:/g, '-');
    const filename = `exported_data_${timestamp}`;
    const csv = generateCsv(csvConfig)(simplifiedMurderData);
    download({ filename })(csv);
  };

  const pdfConfig = {
    columnHeaders: [
      { header: t('commonTerms.ID'), accessorKey: 'id' },
      { header: t('fields.time_of_murder'), accessorKey: 'time_of_murder' },
      { header: t('fields.first_name'), accessorKey: 'first_name' },
      { header: t('fields.last_name'), accessorKey: 'last_name' },
      { header: t('fields.city'), accessorKey: 'city' },
      { header: t('fields.method_of_murder'), accessorKey: 'method_of_murder' },
      { header: t('fields.region'), accessorKey: 'region' },
      { header: t('fields.motive_of_murder'), accessorKey: 'motive_of_murder' },
      { header: t('fields.social_background'), accessorKey: 'social_background' },
      { header: t('fields.age_group'), accessorKey: 'age_group' },
      { header: t('fields.date_of_murder'), accessorKey: 'date_of_murder' },
      { header: t('fields.age'), accessorKey: 'age' },
      { header: t('fields.gender'), accessorKey: 'gender' },
      { header: t('fields.religion'), accessorKey: 'religion' },
      { header: t('fields.career'), accessorKey: 'career' }
    ]
  };

  const handleExportDataPDF = () => {
    const timestamp = new Date().toISOString().replace(/:/g, '-');
    const filename = `exported_data_${timestamp}.pdf`;
    const doc = new jsPDF({
      orientation: 'l'
    });
    doc.addFileToVFS('NotoKufiArabic-Regular-normal.ttf', arabicFont);
    doc.addFont('NotoKufiArabic-Regular-normal.ttf', 'NotoKufiArabic-Regular', 'normal');

    doc.addFileToVFS('NotoSansHebrew-Regular-normal.ttf', hebrewFont);
    doc.addFont('NotoSansHebrew-Regular-normal.ttf', 'NotoSansHebrew-Regular', 'normal');

    if(i18n.language === 'ar'){
      doc.setFont("NotoKufiArabic-Regular");
    }else{
      doc.setFont("NotoSansHebrew-Regular");
    }

    const headers = pdfConfig.columnHeaders.map(column => column.header);
    const data = simplifiedMurderData.map((row: any) =>
    pdfConfig.columnHeaders.map((column: any) => {
      const value = row[column.accessorKey] !== undefined ? String(row[column.accessorKey]) : '';
      return reverseStringFn(value);
    })
  );
  
    autoTable(doc, {
      head: [headers],
      body: data,
      styles: {font: "NotoSansHebrew-Regular", fontSize:8}
    });

    doc.save(filename);
  };


  const columns = useMemo<MRT_ColumnDef<murderType>[]>(
    () => createColumns(t, i18n),
    [i18n.language],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableFacetedValues: true,
    columnResizeDirection: 'rtl',
    enableColumnFilterModes: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: 'static',
    enableRowActions: is_superuser,
    state: {
      isLoading: isLoading,
    },
    enableStickyHeader: true,
    renderRowActions: ({ row, table }: any) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        <IconButton
          color="error"
          onClick={() => { handleOpenDeleteModal(row.original) }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => { handleOpenEditModal(row.original) }}>
          <EditIcon />
        </IconButton>
      </Box>
    ),
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportDataCSV} variant='contained' color='success' startIcon={<FileDownloadIcon />} disabled={data.length === 0} sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
          opacity: 0.9
        }}>
          {t('commonTerms.exportDataExcel')}
        </Button>
        <Button onClick={handleExportDataPDF} variant='contained' color='error' startIcon={<FileDownloadIcon />} disabled={data.length === 0} sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
          opacity: 0.9,
        }}>
          {t('commonTerms.exportDataPDF')}
        </Button>
        <Button onClick={handleNavigateToChart} variant='contained' color='primary' startIcon={<InsightsIcon />} disabled={data.length === 0} sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
          opacity: 0.9,
        }}>
          {t('commonTerms.viewInsights')}
        </Button>
      </Box>
    ),
    enableSelectAll: false,
    enableColumnOrdering: true,
    enableFullScreenToggle: false,
    autoResetAll: false,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      pagination: { pageSize: 20, pageIndex: 0 },
      showGlobalFilter: true,
      columnVisibility: { 'details': false, 'notes': false, 'created_by.username': false, 'updated_by.username': false, 'created_at': false, 'updated_at': false }
    },
    localization: i18n.language == 'ar' ? MRT_Localization_AR : MRT_Localization_HE,
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        border: '1px dashed #e0e0e0',
      },
    },
  });

  const simplifiedMurderData = useMemo(
    () =>
      table.getFilteredRowModel().rows.map(row => row.original).map((item: murderType) => ({
        id: item.id,
        date_of_murder: item.date_of_murder,
        month: dayjs(item.date_of_murder).format('DD/MM/YYYY').split('/')[1],
        year:dayjs(item.date_of_murder).format('DD/MM/YYYY').split('/')[2],
        time_of_murder: i18n.language === 'ar' ? item.time_of_murder.name_arabic : item.time_of_murder.name_hebrew,
        first_name: item.first_name,
        last_name: item.last_name,
        city: i18n.language === 'ar' ? item.city.name_arabic : item.city.name_hebrew,
        method_of_murder: i18n.language === 'ar' ? item.method_of_murder.name_arabic : item.method_of_murder.name_hebrew,
        region: item.region.number,
        motive_of_murder: i18n.language === 'ar' ? item.motive_of_murder.name_arabic : item.motive_of_murder.name_hebrew,
        social_background: i18n.language === 'ar' ? item.social_background.name_arabic : item.social_background.name_hebrew,
        career: item.career,
        age_group: item.age_group.name,
        age: item.age,
        gender: t(item.gender),
        notes: item.notes,
        details: item.details,
        religion: i18n.language === 'ar' ? item.religion.name_arabic : item.religion.name_hebrew
      })),
    [table.getFilteredRowModel().rows, i18n.language]
  );
  return (
    <>
      <MaterialReactTable table={table} />
      <CustomModal open={openDelete} onClose={handleCloseDeleteModal}>
        <DeleteConfirmation
          target={target!}
          onClose={handleCloseDeleteModal}
          onDelete={handleDeleteDone}
          message={t('deleteMurderConfirmation')} />
      </CustomModal>

      <CustomModal open={openEdit} onClose={handleCloseEditModal}>
        <MurderForm
          data={target!}
          onSubmit={onUpdate}
          onClose={handleCloseEditModal}
          title={t('updateMurderInfo')}
          type='edit' />
      </CustomModal>
    </>
  );
};

export default MurderTable;


import { createAsyncThunk } from '@reduxjs/toolkit';
import {getAgeGroupService} from 'services/ageGroup'

export const getAgeGroupAction = createAsyncThunk(
  'ageGroup/getAgeGroups',
  async (_ , thunkAPI) => {
    try {
      return await getAgeGroupService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import React, { useEffect, useState, useTransition } from 'react';
import { Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Container, Typography, Grid, InputLabel, FormControl, MenuItem, Select, FormHelperText, TextareaAutosize } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import { murderFormType, murderType } from 'types/murder';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/en-gb'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Textarea } from 'components/common/textArea'
import { getAgeGroupAction } from 'store/actions/ageGroup';
import { getCityAction } from 'store/actions/city';
import { getMethodOfMurderAction } from 'store/actions/methodOfMurder';
import { getMotiveAction } from 'store/actions/motive';
import { getRegionAction } from 'store/actions/region';
import { getSocialBackgroundAction } from 'store/actions/socialBackground';
import { getTimeOfMurderAction } from 'store/actions/timeOfMurder';
import { getReligionAction } from 'store/actions/religion';

import dayjs from 'dayjs';
import Loader from 'components/common/loader';
import CityAutocomplete from 'components/common/autoComplete';

interface MurderFormFormProps {
    data?: murderType;
    title: string;
    onSubmit: (formData: murderFormType | murderType | any) => void;
    onClose: () => void;
    type: 'add' | 'edit'
}

const MurderForm: React.FC<MurderFormFormProps> = ({ data, onSubmit, title, onClose, type }) => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { timeOfMurder, city, methodOfMurder, region, religion, motive, ageGroup, socialBackground } = useAppSelector((state) => state.store)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        dispatch(getAgeGroupAction())
        dispatch(getCityAction())
        dispatch(getMethodOfMurderAction())
        dispatch(getMotiveAction())
        dispatch(getRegionAction())
        dispatch(getRegionAction())
        dispatch(getSocialBackgroundAction())
        dispatch(getTimeOfMurderAction())
        dispatch(getReligionAction())
        setLoading(false)
    }, [])

    const validationSchema = Yup.object({
        date_of_murder: Yup.string().required(t('requiredFields.date_of_murder')),
        time_of_murder: Yup.string().required(t('requiredFields.time_of_murder')),
        last_name: Yup.string().required(t('requiredFields.last_name')),
        first_name: Yup.string().required(t('requiredFields.first_name')),
        city: Yup.string().required(t('requiredFields.city')),
        age: Yup.string().required(t('requiredFields.age')),
        gender: Yup.string().required(t('requiredFields.gender')),
        method_of_murder: Yup.string().required(t('requiredFields.method_of_murder')),
        region: Yup.string().required(t('requiredFields.region')),
        religion: Yup.string().required(t('requiredFields.religion')),
        motive_of_murder: Yup.string().required(t('requiredFields.motive_of_murder')),
        age_group: Yup.string().required(t('requiredFields.age_group')),
        social_background: Yup.string().required(t('requiredFields.social_background')),
        career: Yup.string()


    });

    let initData = {
        id: data?.id || '',
        date_of_murder: data?.date_of_murder || dayjs(new Date).format('YYYY-MM-DD'),
        time_of_murder: data?.time_of_murder.id || '',
        last_name: data?.last_name || '',
        first_name: data?.first_name || '',
        city: data?.city.id || '',
        age: data?.age || '',
        gender: data?.gender || '',
        method_of_murder: data?.method_of_murder.id || '',
        notes: data?.notes || '',
        region: data?.region.id || '',
        details: data?.details || '',
        religion: data?.religion.id || '',
        motive_of_murder: data?.motive_of_murder.id || '',
        age_group: data?.age_group.id || '',
        social_background: data?.social_background.id || '',
        career: data?.career || '',
    }

    const formik = useFormik({
        initialValues: initData,
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
            onClose()
        },
    });
    return (
        <>
            {loading ?
                <Loader loading={loading} />

                :

                <Container maxWidth="sm" sx={{
                    "& .MuiInputLabel-root": {
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset > legend": {
                            fontSize: '1.3rem',
                        },
                    },
                    margin: '10px',
                }}>
                    <Typography variant="h5">{title}</Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1} mt={2}>
                            <Grid item xs={6} my={1}>
                                <FormControl fullWidth error={formik.touched.date_of_murder && Boolean(formik.errors.date_of_murder)}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                        <DatePicker
                                            name="date_of_murder"
                                            label={t('fields.date_of_murder')}
                                            value={dayjs(formik.values.date_of_murder)}
                                            onChange={(date: Date | any) => {
                                                formik.setFieldValue('date_of_murder', dayjs(date).format('YYYY-MM-DD'));
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <FormHelperText>{formik.touched.date_of_murder && String(formik.errors.date_of_murder ? formik.errors.date_of_murder : '')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} my={1}>
                                <FormControl fullWidth error={formik.touched.time_of_murder && Boolean(formik.errors.time_of_murder)}>
                                    <InputLabel id="time_of_murder">{t('fields.time_of_murder')}</InputLabel>
                                    <Select
                                        labelId="time_of_murder"
                                        id="time_of_murder"
                                        name='time_of_murder'
                                        value={formik.values.time_of_murder}
                                        label={t('fields.time_of_murder')}
                                        error={formik.touched.time_of_murder && Boolean(formik.errors.time_of_murder)}
                                        onChange={formik.handleChange}>
                                        {
                                            timeOfMurder.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {i18n.language === 'ar' ? (item.name_arabic === 'NA' ? t('NA') : item.name_arabic) : item.name_hebrew}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.time_of_murder && String(formik.errors.time_of_murder ? formik.errors.time_of_murder : '')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} my={1}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label={t('fields.last_name')}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} my={1}>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label={t('fields.first_name')}
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <TextField
                                    fullWidth
                                    id="age"
                                    name="age"
                                    type='number'
                                    label={t('fields.age')}
                                    value={formik.values.age}
                                    onChange={(input: any) => {
                                        formik.setFieldValue('age', input.target.value);
                                    }}
                                    error={formik.touched.age && Boolean(formik.errors.age)}
                                    helperText={formik.touched.age && formik.errors.age}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} my={1}>
                                <FormControl fullWidth error={formik.touched.city && Boolean(formik.errors.city)}>
                                    <CityAutocomplete textFieldProps={{
                                        id: "city",
                                        name: 'city',
                                        value: formik.values.city,
                                        label: t('fields.city'),
                                        error: formik.touched.city && Boolean(formik.errors.city),
                                    }} data={city.data} onChange={(value) => {
                                        formik.setFieldValue('city', value);
                                    }} />
                                    <FormHelperText>{formik.touched.city && formik.errors.city}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                    <InputLabel id="gender">{t('fields.gender')}</InputLabel>
                                    <Select
                                        labelId="gender"
                                        id="gender"
                                        name='gender'
                                        value={formik.values.gender}
                                        label={t('fields.gender')}
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        onChange={formik.handleChange}>
                                        <MenuItem value='Female'>{t('Female')}</MenuItem>
                                        <MenuItem value='Male'>{t('Male')}</MenuItem>
                                    </Select>
                                    <FormHelperText>{formik.touched.gender && formik.errors.gender}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} my={1}>
                                <FormControl fullWidth error={formik.touched.method_of_murder && Boolean(formik.errors.method_of_murder)}>
                                    <InputLabel id="MethodOfMurder">{t('fields.method_of_murder')}</InputLabel>
                                    <Select
                                        labelId="method_of_murder"
                                        id="method_of_murder"
                                        name='method_of_murder'
                                        value={formik.values.method_of_murder}
                                        label={t('fields.method_of_murder')}
                                        error={formik.touched.method_of_murder && Boolean(formik.errors.method_of_murder)}
                                        onChange={formik.handleChange}>
                                        {
                                            methodOfMurder.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {i18n.language === 'ar' ? (item.name_arabic === 'NA' ? t('NA') : item.name_arabic) : item.name_hebrew}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText>{formik.touched.method_of_murder && formik.errors.method_of_murder}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} my={1}>
                                <Textarea
                                    placeholder={t('fields.notes')}
                                    minRows={4}
                                    id='notes'
                                    name='notes'
                                    value={formik.values.notes}
                                    onChange={formik.handleChange} />
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.region && Boolean(formik.errors.region)}>
                                    <InputLabel id="region">{t('fields.region')}</InputLabel>
                                    <Select
                                        labelId="region"
                                        id="region"
                                        name='region'
                                        value={formik.values.region}
                                        label={t('fields.region')}
                                        error={formik.touched.region && Boolean(formik.errors.region)}
                                        onChange={formik.handleChange}>

                                        {
                                            region.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.number}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.region && formik.errors.region}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} my={1}>
                                <Textarea
                                    placeholder={t('fields.details')}
                                    minRows={4}
                                    id='details'
                                    name='details'
                                    value={formik.values.details}
                                    onChange={formik.handleChange} />
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.religion && Boolean(formik.errors.religion)}>
                                    <InputLabel id="religion">{t('fields.religion')}</InputLabel>
                                    <Select
                                        labelId="religion"
                                        id="religion"
                                        name='religion'
                                        value={formik.values.religion}
                                        label={t('fields.religion')}
                                        error={formik.touched.religion && Boolean(formik.errors.religion)}
                                        onChange={formik.handleChange}>

                                        {
                                            religion.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {i18n.language === 'ar' ? (item.name_arabic === 'NA' ? t('NA') : item.name_arabic) : item.name_hebrew}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.religion && formik.errors.religion}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.motive_of_murder && Boolean(formik.errors.motive_of_murder)}>
                                    <InputLabel id="motive_of_murder">{t('fields.motive_of_murder')}</InputLabel>
                                    <Select
                                        labelId="motive_of_murder"
                                        id="motive_of_murder"
                                        name='motive_of_murder'
                                        value={formik.values.motive_of_murder}
                                        label={t('fields.motive_of_murder')}
                                        onChange={formik.handleChange}>

                                        {
                                            motive.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {i18n.language === 'ar' ? (item.name_arabic === 'NA' ? t('NA') : item.name_arabic) : item.name_hebrew}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.motive_of_murder && formik.errors.motive_of_murder}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.social_background && Boolean(formik.errors.social_background)}>
                                    <InputLabel id="social_background">{t('fields.social_background')}</InputLabel>
                                    <Select
                                        labelId="social_background"
                                        id="social_background"
                                        name='social_background'
                                        value={formik.values.social_background}
                                        label={t('fields.social_background')}
                                        error={formik.touched.social_background && Boolean(formik.errors.social_background)}
                                        onChange={formik.handleChange}>

                                        {
                                            socialBackground.data.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {i18n.language === 'ar' ? (item.name_arabic === 'NA' ? t('NA') : item.name_arabic) : item.name_hebrew}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.social_background && formik.errors.social_background}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} my={1}>
                                <FormControl fullWidth error={formik.touched.age_group && Boolean(formik.errors.age_group)}>
                                    <InputLabel id="age_group">{t('fields.age_group')}</InputLabel>
                                    <Select
                                        labelId="age_group"
                                        id="age_group"
                                        name='age_group'
                                        value={formik.values.age_group}
                                        label={t('fields.age_group')}
                                        error={formik.touched.age_group && Boolean(formik.errors.age_group)}
                                        onChange={formik.handleChange}>

                                        {
                                            ageGroup.data.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                    <FormHelperText>{formik.touched.age_group && formik.errors.age_group}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={6} my={1}>
                                    <TextField
                                        fullWidth
                                        id="career"
                                        name="career"
                                        label={t('fields.career')}
                                        value={formik.values.career}
                                        onChange={formik.handleChange}
                                        error={formik.touched.career && Boolean(formik.errors.career)}
                                        helperText={formik.touched.career && formik.errors.career}
                                    />
                            </Grid>      
                        </Grid>       
                        <Grid item xs={4} my={1} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                        }}>
                            <Button variant="contained" color="warning" size='large' onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary" size='large'>
                                {type === 'add' ? t('add') : t('commonTerms.update')}
                            </Button>

                        </Grid>
                    </form>
                </Container>
            }
        </>
    );
};

export default MurderForm;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { murderFormType } from 'types/murder';
import {postMurderService} from 'services/murder'

export const postMurderAction = createAsyncThunk(
  'Murder/postMurder',
  async (data:murderFormType, thunkAPI) => {
    try {
      return await postMurderService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
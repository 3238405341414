export function reverseStringFn(str: string) {

  if(str === 'null') {
      return '';
  }

  if (/[^\u0600-\u06FF\u0590-\u05FF]/.test(str)) {
      return str.split('').reverse().join('');
  } else {
      return str;
  }
}
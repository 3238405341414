import { createAsyncThunk } from '@reduxjs/toolkit';
import {deleteRegionService} from 'services/region'

export const deleteRegionAction = createAsyncThunk(
  'region/deleteRegion',
  async (id:string , thunkAPI) => {
    try {
      return await deleteRegionService(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cityType } from 'types/city';
import {putCityService} from 'services/city'

export const putCityAction = createAsyncThunk(
  'city/putCity',
  async (requestData:{id:number,data:cityType}, thunkAPI) => {
    try {
      return await putCityService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
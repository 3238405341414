import React, { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import {
  deleteMotiveAction,
  getMotiveAction,
  postMotiveAction,
  putMotiveAction,
} from 'store/actions/motive';
import Loader from 'components/common/loader';
import { MotiveTable, MotiveForm } from 'components/motive';
import { toast } from 'react-toastify';
import { clearMotiveState } from 'store/reducers/motiveReducer';
import CustomModal from 'components/common/modal';
import { Box, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { motiveFormType, motiveType } from 'types/motive';

const Motive: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, error, data, success } = useAppSelector(
    (state) => state.store.motive
  );
  const [open, setOpen] = useState(false);

  const handleOpenNewRecordForm = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseNewRecordForm = useCallback(() => {
    setOpen(false);
  }, []);

  const onCreate = (formData: motiveFormType) => {
    dispatch(postMotiveAction(formData));
  };

  const onDelete = useCallback(
    (id: string) => {
      dispatch(deleteMotiveAction(id));
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (data: motiveType) => {
      const formData = {
        id: data.id,
        data: data,
      };

      dispatch(putMotiveAction(formData));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getMotiveAction());

    return () => {
    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(t(error));
    }

    if (success) {
      toast.success(t(success));
    }

    dispatch(clearMotiveState());
  }, [error, success, dispatch, t]);
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Box p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{fontWeight:'bold'}} mb={4}>
              {t('MotivesPage')}
            </Typography>

            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpenNewRecordForm}
              sx={{
                position:'fixed',
                top: '10%',
                right: '2%',
                zIndex: 1000
               }}
            >
              <AddIcon />
            </Fab>
          </Box>

          <Box
            sx={{
              alignSelf: 'center',
              width: '100%',
            }}
          >
            <MotiveTable
              data={data}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          </Box>
          <CustomModal open={open} onClose={handleCloseNewRecordForm}>
            <MotiveForm
              onSubmit={onCreate}
              title={t('addMotive')}
              onClose={handleCloseNewRecordForm}
              type='add'
            />
          </CustomModal>
        </Box>
      )}
    </>
  );
};

export default Motive;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {getReligionService} from 'services/religion'

export const getReligionAction = createAsyncThunk(
  'Religion/getReligion',
  async (_ , thunkAPI) => {
    try {
      return await getReligionService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import { socialBackgroundFormType } from 'types/socialBackground';
import {putSocialBackgroundService} from 'services/socialBackground'

export const putSocialBackgroundAction = createAsyncThunk(
  'socialBackground/putRegion',
  async (requestData:{id:number,data:socialBackgroundFormType}, thunkAPI) => {
    try {
      return await putSocialBackgroundService(requestData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import {getTimeOfMurderService} from 'services/timeOfMurder'

export const getTimeOfMurderAction = createAsyncThunk(
  'timeOfMurder/getTimeOfMurders',
  async (_ , thunkAPI) => {
    try {
      return await getTimeOfMurderService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActionType } from 'types/auth';
import {loginService} from 'services/auth'

export const loginAction = createAsyncThunk(
  'auth/login',
  async (data: authActionType, thunkAPI) => {
    try {
      return await loginService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
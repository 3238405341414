import React, { useEffect, useRef } from 'react';
import Chart, { ChartTypeRegistry } from 'chart.js/auto';

import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

interface CustomChartProps {
    chartData: any[];
    labels: string[];
    title?: string;
    type: keyof ChartTypeRegistry;
}

export const CustomChart: React.FC<CustomChartProps> = ({ chartData, labels, title, type = 'doughnut' }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart | null>(null);


    const data = {
        labels: labels,
        datasets: chartData,
    }

    const options: any = {
        responsive: true,
        animation: {
            duration: 0
        },

        plugins: {
            title: {
                display: !!title,
                text: title || '',
                padding: {
                    top: 10,
                    bottom: 10
                },
                font: {
                    size: 40,
                }
            },
            legend: {
                position: 'bottom',
                font: {
                    size: 25,
                }
            },
            datalabels: {
                borderRadius: 4,
                color: 'black',
                font: {
                  weight: 'bold'
                },
                formatter: Math.round,
              }
        },
        aspectRatio: 5 / 3,
        layout: {
          padding: {
            top: 32,
            right: 16,
            bottom: 16,
            left: 8
          }
        },
        elements: {
            line: {
              fill: false,
              tension: 0.4
            }
          },
        scales: {
            x: {
                stacked: true,
                display: type !== 'doughnut' && type !== 'polarArea' ? true : false
            },
            y: {
                stacked: true,
                suggestedMin: 0,
                ticks: {
                    stepSize: 1
                },
                display: type !== 'doughnut' && type !== 'polarArea' ? true : false
            },
        }
    }
    useEffect(() => {
        const calculateCanvasWidth = () => {
            const screenWidth = window.innerWidth;
            const canvasWidth = 0.8 * screenWidth;
            return canvasWidth;
        };


        if (chartRef.current) {
            chartRef.current.width = calculateCanvasWidth();
        }

        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.width = calculateCanvasWidth();
            }
        };

        window.addEventListener('resize', handleResize);

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                if (chartInstance.current) {
                    chartInstance.current.destroy();
                }
                chartInstance.current = new Chart(ctx, {
                    type: type,
                    plugins: [ChartDataLabels],
                    data,
                    options,

                });
            }
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data, labels, title, type]);

    return <canvas id='chartCanvas' ref={chartRef} height={800} />;
};

export default CustomChart;

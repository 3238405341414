import { createAsyncThunk } from '@reduxjs/toolkit';
import {getMethodOfMurderService} from 'services/methodOfMurder'

export const getMethodOfMurderAction = createAsyncThunk(
  'methodOfMurder/getMethodOfMurders',
  async (_ , thunkAPI) => {
    try {
      return await getMethodOfMurderService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getTimeOfMurderAction,
  postTimeOfMurderAction,
  deleteTimeOfMurderAction,
  putTimeOfMurderAction,
} from 'store/actions/timeOfMurder';
import { timeOfMurderStateType, timeOfMurderType } from 'types/timeOfMurder';

const initialState: timeOfMurderStateType = {
  data: [],
  loading: false,
  success: null,
  error: null,
};

export const timeOfMurderSlice = createSlice({
  name: 'timeOfMurder',
  initialState,
  reducers: {
    clearTimeOfMurderState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** GET START *********** \\
      .addCase(getTimeOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getTimeOfMurderAction.fulfilled, (state, action: PayloadAction<{ data: timeOfMurderType[] }>) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getTimeOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** GET END *********** \\
      //
      // *********** POST START *********** \\
      .addCase(postTimeOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postTimeOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 201) {
          state.success = 'timeOfMurderAdded';
          state.loading = false;
          const newTimeOfMurder = action.payload.data;
          state.data.push(newTimeOfMurder);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(postTimeOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      })
      // *********** POST END *********** \\
      //
      // *********** DELETE START *********** \\
      .addCase(deleteTimeOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTimeOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.loading = false;
          state.success = 'timeOfMurderDeleted';
          const deletedTimeOfMurderId = action.payload.data.id;
          state.data = state.data.filter((timeOfMurder:timeOfMurderType) => timeOfMurder.id !== deletedTimeOfMurderId);
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(deleteTimeOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 400) {
          state.loading = false;
          state.error = 'cannotDeleteTimeOfMurder';
        } else {
          state.error = 'somethingWentWrong';
        }
      })
      // *********** DELETE END *********** \\
      //
      // *********** PUT START *********** \\
      .addCase(putTimeOfMurderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putTimeOfMurderAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.success = 'timeOfMurderUpdated';
          state.loading = false;
          const updatedTimeOfMurder = action.payload.data;
          const index = state.data.findIndex((timeOfMurder:timeOfMurderType) => timeOfMurder.id === updatedTimeOfMurder.id);
          if (index !== -1) {
            state.data[index] = updatedTimeOfMurder;
          }
        } else {
          state.loading = false;
          state.error = 'somethingWentWrong';
        }
      })
      .addCase(putTimeOfMurderAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = 'somethingWentWrong';
      });
    // *********** PUT END *********** \\
  },
});

export const { clearTimeOfMurderState } = timeOfMurderSlice.actions;
export default timeOfMurderSlice.reducer;

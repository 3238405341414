import React, { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useTranslation } from 'react-i18next';
import {
  deleteSocialBackgroundAction,
  getSocialBackgroundAction,
  postSocialBackgroundAction,
  putSocialBackgroundAction,
} from 'store/actions/socialBackground';
import Loader from 'components/common/loader';
import { toast } from 'react-toastify';
import { clearSocialBackgroundState } from 'store/reducers/socialBackgroundReducer';
import CustomModal from 'components/common/modal';
import { Box, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { socialBackgroundFormType, socialBackgroundType } from 'types/socialBackground';
import { SocialBackgroundForm, SocialBackgroundTable } from 'components/socialBackground';

const SocialBackground: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loading, error, data, success } = useAppSelector(
    (state) => state.store.socialBackground
  );
  const [open, setOpen] = useState(false);

  const handleOpenNewRecordForm = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseNewRecordForm = useCallback(() => {
    setOpen(false);
  }, []);

  const onCreate = (formData: socialBackgroundFormType) => {
    dispatch(postSocialBackgroundAction(formData));
  };

  const onDelete = useCallback(
    (id: string) => {
      dispatch(deleteSocialBackgroundAction(id));
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (data: socialBackgroundType) => {
      const formData = {
        id: data.id,
        data: data,
      };
      dispatch(putSocialBackgroundAction(formData));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getSocialBackgroundAction());

    return () => {
    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(t(error));
    }

    if (success) {
      toast.success(t(success));
    }

    dispatch(clearSocialBackgroundState());
  }, [error, success, dispatch, t]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Box p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }} mb={4}>
              {t('socialBackgroundPage')}
            </Typography>

            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpenNewRecordForm}
              sx={{
                position:'fixed',
                top: '10%',
                right: '2%',
                zIndex: 1000
               }}
            >
              <AddIcon />
            </Fab>
          </Box>

          <Box sx={{
              alignSelf: 'center',
              width: '100%',
            }}>
              <SocialBackgroundTable
                data={data}
                onDelete={onDelete}
                onUpdate={onUpdate}/>
          </Box>
          <CustomModal open={open} onClose={handleCloseNewRecordForm}>
          <SocialBackgroundForm
              onSubmit={onCreate}
              title={t('addBackground')}
              onClose={handleCloseNewRecordForm}
              type='add'
            />
          </CustomModal>
        </Box>
      )}
    </>
  );
}

export default SocialBackground;
